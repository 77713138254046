<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('table.head.memRegDt') }}</span></div>
          <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault"/>
        </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-check searchbar-content">
          <div class="toggle-container">
            <div class="toggle-item">
              <span class="toggle-title">{{ $t('user.login') }}</span>
              <select v-model="reqData.loginYn">
                <option value="">ALL</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item">
              <span class="toggle-title">{{ $t('user.bet') }}</span>
              <select v-model="reqData.betYn">
                <option value="">ALL</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item">
              <span class="toggle-title">{{ $t('user.deleted') }}</span>
              <select v-model="reqData.memDelYn">
                <option value="">ALL</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item" v-if="selectOption !== 'blackYn'">
              <span class="toggle-title">{{ $t('user.blacked') }}</span>
              <select v-model="reqData.memBlackYn">
                <option value="">ALL</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item" v-if="selectOption !== 'lockYn'">
              <span class="toggle-title">{{ $t('user.blocked') }}</span>
              <select v-model="reqData.memLockYn">
                <option value="">ALL</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
          </div>
        </div>
        <div :class="'searchbar-content'">
           <div class="title"><span>{{ $t('table.head.category') }}</span></div>
           <div>
              <select class="mr-5" v-model="searchOptionText.option">
               <option value="memId">{{ $t('searchArea.id') }}</option>
               <option value="memNick">{{ $t('searchArea.nick') }}</option>
               <option value="memName">{{ $t('searchArea.account') }}</option>
               <option value="memPhone">{{ $t('searchArea.tel') }}</option>
             </select>
             <input type="text" class="mr-5" v-model="searchOptionText.text"/>
             <button class="btn-search mr-5" type="button" @click="loadMemberList(selectMem ? selectMem.memId : null)">
               <i class="fas fa-search"></i>
             </button>
           </div>
        </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper section">
      <div class="left box">
        <h2>
          {{ $t('common.partnerList') }}
          <div class="toggle-item">
            <span class="toggle-title">{{ $t('common.subMemPrint') }}</span>
            <input type="checkbox" class="set-switch" v-model="isTreeOption"/>
          </div>
        </h2>
        <ul class="list">
          <template v-for="(item1) in memberList" :key="item1.memId">
            <li :class="[item1.partnerLevel, item1.cnt ? 'morebtn': '', {'moreOn':item1.children && item1.children.length > 0}]">
               <template v-if="item1.partnerCnt">
                  <span @click="listOpen(item1)" :class="{'moreOn':item1.children && item1.children.length > 0}"></span>
               </template>
               <template v-else>
                  <span @click="listOpen(item1)" class="none"></span>
               </template>
               <div @click="loadMemberList(item1)" >
                 <em>{{ $t('common.seniorA') }}</em>
                 <div :class="{ 'active': selectedLi === item1.memId }">
                    {{item1.memId}}
                    <span class="nick">{{item1.memNick}}</span>
                    <template v-if="item1.cnt || item1.allCnt">
                     ({{isTreeOption ? item1.cnt : item1.allCnt}})
                    </template>
                 </div>
               </div>
               <ul class="more" v-if="item1.children && item1.children.length > 0">
                 <template v-for="(item2) in item1.children" :key="item2.memId">
                  <li :class="[item2.partnerLevel, item2.cnt ? 'morebtn': '']">
                     <template v-if="item2.partnerCnt">
                        <span @click="listOpen(item2)" :class="{'moreOn':item2.children && item2.children.length > 0}"></span>
                     </template>
                     <template v-else>
                        <span @click="listOpen(item2)" class="none"></span>
                     </template>

                     <div @click="loadMemberList(item2)">
                       <em>{{ $t('common.masterA') }}</em>
                       <div :class="{ 'active': selectedLi === item2.memId }">
                          {{item2.memId}}
                          <span class="nick">{{item2.memNick}}</span>
                          <template v-if="item2.cnt || item2.allCnt">
                            ({{isTreeOption ? item2.cnt : item2.allCnt}})
                          </template>
                       </div>
                     </div>
                     <ul class="more" v-if="item2.children && item2.children.length > 0">
                       <template v-for="(item3) in item2.children" :key="item3.memId">
                         <li :class="[item3.partnerLevel, item3.cnt ? 'morebtn': '']">
                           <template v-if="item3.partnerCnt">
                              <span @click="listOpen(item3)" :class="{'moreOn':item3.children && item3.children.length > 0}"></span>
                           </template>
                           <template v-else>
                              <span @click="listOpen(item3)" class="none"></span>
                           </template>
                           <div @click="loadMemberList(item3)">
                             <em>{{ $t('common.juniorA') }}</em>
                             <div :class="{ 'active': selectedLi === item3.memId }">
                                {{item3.memId}}
                                <span class="nick">{{item3.memNick}}</span>
                                <template v-if="item3.cnt || item3.allCnt">
                                  ({{isTreeOption ? item3.cnt : item3.allCnt}})
                                </template>
                             </div>
                           </div>
                           <ul class="more" v-if="item3.children && item3.children.length > 0">
                             <template v-for="(item4) in item3.children" :key="item4.memId">
                               <li :class="[item4.partnerLevel, item4.cnt ? 'morebtn': '']">
                                 <template v-if="item4.partnerCnt">
                                    <span @click="listOpen(item4)" :class="{'moreOn':item4.children && item4.children.length > 0}"></span>
                                 </template>
                                 <template v-else>
                                    <span @click="listOpen(item4)" class="none"></span>
                                 </template>
                                 <div @click="loadMemberList(item4)">
                                   <em>{{ item4.partnerLevelName }}</em>
                                   <div :class="{ 'active': selectedLi === item4.memId }">
                                      {{item4.memId}}
                                      <span class="nick">{{item4.memNick}}</span>
                                      <template v-if="item4.cnt || item4.allCnt">
                                        ({{isTreeOption ? item4.cnt : item4.allCnt}})
                                      </template>
                                   </div>
                                 </div>
                               </li>
                               <ul class="more" v-if="item4.children && item4.children.length > 0">
                                 <template v-for="(item5) in item4.children" :key="item5.memId">
                                   <li :class="[item5.partnerLevel, item5.cnt ? 'morebtn': '']">
                                     <div @click="loadMemberList(item5)">
                                       <em>{{ item5.partnerLevelName }}</em>
                                       <div :class="{ 'active': selectedLi === item5.memId }">
                                          {{item5.memId}}
                                          <span class="nick">{{item5.memNick}}</span>
                                          <template v-if="item5.cnt || item5.allCnt">
                                            ({{isTreeOption ? item5.cnt : item5.allCnt}})
                                          </template>
                                       </div>
                                     </div>
                                   </li>
                                   <ul class="more" v-if="item5.children && item5.children.length > 0">
                                     <template v-for="(item6) in item5.children" :key="item6.memId">
                                       <li :class="[item6.partnerLevel, item6.cnt ? 'morebtn': '']">
                                         <div @click="loadMemberList(item6)">
                                           <em>{{ item5.partnerLevelName }}</em>
                                           <div :class="{ 'active': selectedLi === item6.memId }">
                                              {{item6.memId}}
                                              <span class="nick">{{item6.memNick}}</span>
                                              <template v-if="item6.cnt">
                                                ({{item5.cnt}})
                                              </template>
                                           </div>
                                         </div>
                                       </li>
                                     </template>
                                   </ul>
                                 </template>
                               </ul>
                             </template>
                           </ul>

                         </li>
                       </template>
                     </ul>
                  </li>
                 </template>
               </ul>
            </li>
          </template>
        </ul>
      </div>
      <div class="right box">
        <div>
          <div class="table-wrapper">
            <table class="mainTable">
               <colgroup>
                 <col style="width:8%;">
                 <col style="width:8%;">
                 <col style="width:5%;">
                 <col style="width:7%;">
                 <col style="width:10%;">
                 <col style="width:10%;">
                 <col style="width:12%;">
                 <col style="width:10%;">
                 <col style="width:10%;">
                 <col style="width:10%;">
                 <col style="width:10%;">
               </colgroup>
               <thead>
                 <tr>
                  <th>{{ $t("table.head.memIdNick") }}</th>
                  <th>{{ $t("table.head.bankname") }} (<img src="@/assets/img/icon_memos.svg" />)</th>
                  <th>{{ $t("table.head.level") }}</th>
                  <th>{{ $t("table.head.action2") }}</th>
                  <th>{{ $t("table.head.cashAmt") }}</th>
                  <th>{{ $t("table.head.userPT") }}</th>
                  <th>{{ $t("table.head.allInOut") }}</th>
                  <th>{{ $t("header.inoutAmt") }}</th>
                  <th>{{ $t("table.head.betwlt") }}</th>
                  <th>{{ $t("table.head.action") }}</th>
                  <th>{{ $t("table.head.lastLoginIp") }}</th>
                 </tr>
               </thead>
               <tbody>
                 <template v-if="normalMemberList.length > 0">
                  <template v-for="(item) in normalMemberList" :key="item.memId">
                     <tr>
                       <td class="name">
                         <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">
                           <template v-if="item.memDelYn === 'Y'">
                             <del>{{ item.memId }}({{ item.memLevel }})</del>
                           </template>
                           <template v-else>
                             {{ item.memId }}({{ item.memLevel }})
                           </template>
                         </button>
                         <span class="nameId">
                           <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">
                             <template v-if="item.memDelYn == 'Y'">
                               <del>{{ item.memNick }}</del>
                             </template>
                             <template v-else>
                               {{ item.memNick }}
                             </template>
                           </button>
                         </span>
                       </td>
                       <td>
                          <div class="memo-btn">
                             <a @click="item.isOpenMemo = !item.isOpenMemo">{{item.memName}}</a>
                             <div class="memo-box-wrap" v-if="item.isOpenMemo">
                                <div class="memo-box-in">
                                   <div class="memo-box-title">Memo</div>
                                   <div class="memo-box-content">
                                     <textarea :placeholder="$t('txt.enterNote')" v-model="item.memo"></textarea>
                                   </div>
                                </div>
                                <div class="memo-box-btn">
                                     <a @click="setMemo(item)">{{ $t('searchArea.modify') }}</a>
                                     <a @click="item.isOpenMemo = false;">{{ $t('searchArea.cancel') }}</a>
                                </div>
                             </div>
                          </div>
                       </td>
                       <td>
                          <span class="levelbox">
                            <span :class="item.partnerLevel">{{computedPartnerLevel(item)}}</span>
                          </span>
                       </td>
                       <td class="actionbtn">
                         <div class="action">
                            <a class="phone">
                              <img src="@/assets/img/icon_phone.svg" style="height:20px" @click="item.isPhone = !item.isPhone"/>
                              <span class="popup" v-if="item.isPhone">{{ item.memPhone }}</span>
                            </a>
                            <a class="acc">
                              <img src="@/assets/img/icon_account.svg" style="height:24px" @click="item.isbank = !item.isbank"/>
                              <span class="popup" v-if="item.isbank">{{ item.bank }}<br>{{ item.bankacc }}</span>
                            </a>
                         </div>
                       </td>
                       <td>
                          <div class="btn-inout-wrap">
                             <span class="roboto">{{ numberWithCommas(item.cashAmt) }}</span>
                             <a @click="openMemberPopup(item, 'isOpenCash')" class="btn-inout">{{ $t('searchArea.listTypeB') }}</a>
                          </div>
                       </td>
                       <td>
                          <div class="btn-inout-wrap">
                             <span class="roboto">{{ numberWithCommas(item.pointAmt) }}</span>
                             <a @click="openMemberPopup(item, 'isOpenPoint')" class="btn-inout">{{ $t('searchArea.listTypeB') }}</a>
                          </div>
                       </td>
                       <td>
                         <ul class="money">
                           <li>
                             <em>{{ $t('table.body.totalCashInAmt') }}</em>
                             <span class="roboto">{{ numberWithCommas(item.totalCashInAmt) }}</span>
                           </li>
                           <li>
                             <em>{{ $t('table.body.totalCashOutAmt') }}</em>
                             <span class="roboto">{{ numberWithCommas(item.totalCashOutAmt) }}</span>
                           </li>
                           <!--li>
                             <em>{{ $t('table.body.diff') }}</em>
                             <span class="rdc">{{ numberWithCommas(item.totalCashDiff) }}</span>
                           </li-->
                         </ul>
                       </td>
                       <td>
                         <span :class="{'rdc' : item.totalCashDiff < 0}" class="roboto">{{ numberWithCommas(item.totalCashDiff) }}</span>
                       </td>
                       <td>
                         <span :class="{'rdc' : item.totalWlAmt < 0}" class="roboto">{{ numberWithCommas(item.totalWlAmt) }}</span>
                       </td>
                       <td class="actionbtn">
                         <div class="action">
                            <a class="btn-inout" @click="parentLinkMove('/bet/list/all', item.memId)">{{ $t('table.head.betList') }}</a>
                            <template v-if="item.memColor === 'blue'">
                               <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-bl">{{ $t('table.head.userState') }}</a>
                            </template>
                            <template v-if="item.memColor === 'black'">
                               <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-bk">{{ $t('table.head.userState') }}</a>
                            </template>
                            <template v-if="item.memColor === 'red'">
                               <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-rd">{{ $t('table.head.userState') }}</a>
                            </template>
                           <!--a class="moneyb" @click="openMemberPopup(item, 'isOpenCash')"><img src="@/assets/img/icon_in.svg" /></a> @click="openMemberPopup(item, 'isOpenCash')"
                           <a class="pointb" @click="openMemberPopup(item, 'isOpenPoint')"><img src="@/assets/img/icon_point.svg" /></a> @click="openMemberPopup(item, 'isOpenPoint')"
                           <a class="memob" @click="openMemberPopup(item, 'isOpenMemo')"><img src="@/assets/img/icon_memo.svg" /></a> < @click="openMemberPopup(item, 'isOpenMemo')" -->
                           <a class="pwb" @click="openMemberPopup(item, 'isOpenPassword')"><img src="@/assets/img/icon_password.svg" /></a> <!--@click="openMemberPopup(item, 'isOpenPassword')"-->
                         </div>
                       </td>
                       <td>
                          <div class="ipwrap">
                            <span class="roboto">{{ item.lastLoginDt?item.lastLoginDt:'-' }}</span>
                            <span class="ip orc"  @click="openMemberPopup(item, 'isOpenBLock')">{{ item.loginIp }}</span>
                          </div>
                       </td>
                       <!-- <td>
                         <div class="ipwrap">
                           <span class="ip">{{ item.lastLoginDt?item.lastLoginDt:'-' }}</span>
                           <span class="ip rdc" onclick="alert('해당IP를 차단하시겠습니까?')">{{ item.loginIp }}</span>
                         </div>
                       </td> -->
                     </tr>
                  </template>
                 </template>
                 <template v-else>
                  <tr>
                     <td colspan="11">
                      {{ $t('txt.noUser') }}
                     </td>
                  </tr>
                 </template>

               </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-wrapper" v-if="isOpenStatus || isOpenCash || isOpenPoint || isOpenPassword || isOpenBLock">
      <div class="modal-wrap2"  v-if="isOpenStatus">
         <div class="sub-title2">
            <h2>{{ selectMember.memName }} {{ $t('txt.statusNow') }}</h2>
            <h4>{{ getDomain() }}</h4>
            <a class="btn" @click="isOpenStatus = false;selectMember = null"><i class="fa fa-times"></i></a>
         </div>
         <div class="modal-content2">
            <ul class="modal-info">
              <li>
                 <span>{{ $t('common.id') }} :</span>
                 <span>{{ selectMember.memId }}</span>
              </li>
              <li>
                 <span>{{ $t('common.nickName') }} :</span>
                 <span class="fc-org">{{ selectMember.memNick }}</span>
              </li>
            </ul>
            <ul class="modal-set">
               <li>
                 <em>{{ $t('common.setBlack') }}</em>
                 <input type="checkbox" class="set-switch" v-model="selectMember.isMemBlack"/>
               </li>
               <li>
                 <em>{{ $t('common.blockSet') }}</em>
                 <input type="checkbox" class="set-switch" v-model="selectMember.isMemLock"/>
               </li>
            </ul>
            <!--<p>{{selectMember.memNick}} {{ $t('txt.statusNows') }}
             <template v-if="selectMember.memColor === 'blue'">
                <span class="normal">{{ $t('common.normal') }}</span>
             </template>
             <template v-if="selectMember.memColor === 'red'">
                <span class="stop">{{ $t('common.stop') }}</span>
             </template>
             <template v-if="selectMember.memColor === 'black'">
                <span class="black">{{ $t('common.block') }}</span>
             </template>
            </p>-->
            <p class="modal-txt">{{ $t('txt.changeStat') }}</p>
            <div class="modal-btns">
              <a @click="setStatus(selectMember)">{{ $t('common.save') }}</a>
            </div>
         </div>
      </div>

      <div class="modal-wrap2" v-if="isOpenCash">
         <div class="sub-title2">
            <h2>{{ $t('txt.memberManager') }}</h2>
            <h4>{{ getDomain() }}</h4>
            <a class="btn" @click="isOpenCash = false;selectMember = null"><i class="fa fa-times"></i></a>
         </div>
         <div class="modal-content2">
            <ul class="modal-info">
              <li>
                 <span>{{ $t('common.id') }} :</span>
                 <span>{{ selectMember.memId }}</span>
              </li>
              <li>
                 <span>{{ $t('common.nickName') }} :</span>
                 <span class="fc-org">{{ selectMember.memNick }}</span>
              </li>
              <li>
                 <span>{{ $t('txt.currentCash') }} :</span>
                 <span>{{ numberWithCommas(selectMember.cashAmt) }}</span>
              </li>
              <li>
                 <span>{{ $t('table.head.aftM') }} :</span>
                 <span class="onlyNum">
                    <input id="newCash" class="input" type="number" v-model="selectMember.newCash"/>
                    <label for="newCash">{{ numberWithCommas(selectMember.newCash || 0) }}</label>
                 </span>
              </li>
            </ul>
            <div class="modal-btns">
               <a @click="setCash(selectMember, 'in')">{{ $t('searchArea.payment') }}</a>
               <a @click="setCash(selectMember, 'out')" class="btn">{{ $t('searchArea.retrieval') }}</a>
            </div>
         </div>
      </div>

      <div class="modal-wrap2" v-if="isOpenPoint">
         <div class="sub-title2">
            <h2>{{ $t('txt.memberManagerpoint') }}</h2>
            <h4>{{ getDomain() }}</h4>
            <a class="btn" @click="isOpenPoint = false;selectMember = null"><i class="fa fa-times"></i></a>
         </div>
         <div class="modal-content2">
            <ul class="modal-info">
              <li>
                <span>{{ $t('common.id') }} :</span>
                <span>{{ selectMember.memId }}</span>
              </li>
              <li>
                <span>{{ $t('common.nickName') }} :</span>
                <span class="fc-org">{{ selectMember.memNick }}</span>
              </li>
              <li>
                <span>{{ $t('txt.currentPoint') }} :</span>
                <span>{{ numberWithCommas(selectMember.pointAmt) }}</span>
              </li>
              <li>
                <span>{{ $t('table.head.aftPT') }} :</span>
                <span class="onlyNum">
                   <input id="newPoint" class="input" type="number" v-model="selectMember.newPoint"/>
                   <label for="newPoint">{{ numberWithCommas(selectMember.newPoint || 0) }}</label>
                </span>
              </li>
            </ul>
            <div class="modal-btns">
               <a @click="setPoint(selectMember, 'in')">{{ $t('searchArea.payment') }}</a>
               <a @click="setPoint(selectMember, 'out')" class="btn">{{ $t('searchArea.retrieval') }}</a>
            </div>
         </div>
      </div>

      <!--div class="modal-wrap memo" v-if="isOpenMemo">
        <article class="page-contents-wrap">
          <div class="sub-title">
            <h2>{{ $t('table.head.takeNote') }}</h2>
            <button class="btn" type="button" @click="isOpenMemo = false;selectMember = null"><i class="fa fa-times"></i></button>
          </div>
          <div class="page-content">
            <div class="content">
              <textarea :placeholder="$t('txt.enterNote')" v-model="selectMember.memo"></textarea>
              <div class="btnWrap">
                <a @click="setMemo(selectMember)">{{ $t('button.save') }}</a>
              </div>
            </div>
          </div>
        </article>
      </div-->

      <div class="modal-wrap2" v-if="isOpenPassword">
         <div class="sub-title2">
            <h2>{{ $t('txt.resetPWd') }}</h2>
            <h4>{{ getDomain() }}</h4>
            <a class="btn" @click="isOpenPassword = false;selectMember = null"><i class="fa fa-times"></i></a>
         </div>
         <div class="modal-content2">
            <ul class="modal-info">
              <li>
                <span>{{ $t('common.id') }} :</span>
                <span>{{ selectMember.memId }}</span>
              </li>
              <li>
                <span>{{ $t('common.nickName') }} :</span>
                <span class="fc-org">{{ selectMember.memNick }}</span>
              </li>
            </ul>
            <p class="modal-txt">{{ $t('txt.resetPWd3') }}</p>
            <div class="modal-btns">
               <a @click="setPassword(selectMember)">{{ $t('button.confirm') }}</a>
               <a class="btn" @click="isOpenPassword = false;selectMember = null">{{ $t('searchArea.cancel') }}</a>
            </div>
         </div>
      </div>

      <div class="modal-wrap2" v-if="isOpenBLock">
         <div class="sub-title2">
            <h2>{{ $t('common.ipBlack') }}</h2>
            <h4>{{ getDomain() }}</h4>
            <a class="btn" @click="isOpenBLock = false;selectMember = null"><i class="fa fa-times"></i></a>
         </div>
         <div class="modal-content2">
            <ul class="modal-info">
              <li class="ip-txt">
                <span>IP :</span>
                <span class="fc-org">{{ selectMember.loginIp }}</span>
              </li>
            </ul>
            <p class="modal-txt">{{ $t('txt.blockIP') }}</p>
            <div class="modal-btns">
               <a @click="setDenyIP(selectMember)">{{ $t('button.confirm') }}</a>
               <a class="btn" @click="isOpenBLock = false;selectMember = null">{{ $t('searchArea.cancel') }}</a>
            </div>
         </div>
      </div>
    </div>
  </section>
</template>
<script>
import DateSelector from '@/components/common/DateSelector'
import { addDays } from 'date-fns'
import { getDateStr, thousand } from '@/libs/utils'
import {
  memberList,
  memberListByPartner,
  memberListTree,
  cashIn,
  cashOut,
  memberPointInOut,
  setMemberMemo,
  setMemberResetPassword,
  setMemberDenyIP
} from '@/api/member'
import { TOP_PARTNER_LEVEL } from '@/libs/constants'
import Memo from '@/components/common/memo'

export default {
  name: 'totalList2',
  components: {
    DateSelector,
    Memo
  },
  data: function () {
    return {
      isPhone: false,
      isbank: false,
      selectMember: null,
      isOpenStatus: false,
      isOpenCash: false,
      isOpenPoint: false,
      isOpenMemo: false,
      isOpenPassword: false,
      isOpenBLock: false,
      isTreeOption: true,
      reqData: {
        page: 1,
        count_per_list: 500,
        count_per_page: 30,
        userType: '', // 검색 회원유형 - [전체(""), 일반회원("N"), 파트너("P")]
        startDate: '', // 검색 가입일 기간 시작날짜
        endDate: '', // 검색 가입일 기간 종료날짜
        memId: '', // 검색 키워드 아이디
        memNick: '', // 검색 키워드 닉네임
        memName: '', // 검색 키워드 예금주
        memPhone: '', // 검색 키워드 전화번호
        loginYn: '', // 로그인 옵션 - [전체(""), 가능("Y"), 불가능("N")]
        betYn: '', // 배팅 옵션 - [전체(""), 가능("Y"), 불가능("N")]
        memDelYn: '', // 삭제 옵션 - [전체(""), 해당("Y"), 비해당("N")]
        memBlackYn: '', // 정지 옵션 - [전체(""), 해당("Y"), 비해당("N")]
        memLockYn: '', // 정지 옵션 - [전체(""), 해당("Y"), 비해당("N")]
        todayLoginYn: '', // 금일 로그인회원 옵션 - [ON("Y"), OFF("" or "N")]
        orderStr: '', // 정렬기준 옵션
        treeUserId: '',
        treeOption: 'Y'
      },
      reqDataMember: {
        memId: ''
      },
      searchOptionText: {
        option: 'memId',
        text: ''
      },
      memberList: [],
      normalMemberList: [],
      startDefault: '',
      endDefault: '',
      partnerInfo: {},
      dailyList: [],
      dailyTotal: {},
      selectMem: null,
      selectedLi: null
    }
  },
  watch: {
    isTreeOption () {
      console.log(this.isTreeOption)
      this.reqData.treeOption = this.isTreeOption ? 'Y' : ''
    }
  },
  methods: {
    async setDenyIP (item) {
      const req = {
        memId: item.memId,
        ip: item.loginIp
      }
      const res = await setMemberDenyIP(req)
      if (res.resultCode === '0') {
        alert('로그인 IP 차단 완료')
      } else {
        alert('로그인 IP 차단, 다시 시도해주세요.')
      }
      this.isOpenBLock = false
    },
    getDomain () {
      return location.host
    },
    copy (cop) {
      navigator.clipboard.writeText(cop)
        .then(() => {
          alert('클립보드에 복사했습니다')
        })
	 },
    parentLinkMove (linkTarget, memId) {
      window.open(`${linkTarget}?id=${memId}`, '_blank')
    },
    computedPartnerLevel (user) {
      return TOP_PARTNER_LEVEL[user.partnerLevel]
    },
    thousand,
    getTodayLabel (dateStr) {
      const date = new Date(dateStr)

      const week = ['일', '월', '화', '수', '목', '금', '토']
      const getDay = date.getDay()

      return week[getDay]
    },
    listOpen (item) {
      this.selectMem = item
      this.reqDataMember.memId = item ? item.memId : ''

      if (!item || item.cnt > 0) {
        if (!item || !item.children || item.children.length === 0) {
          memberListTree(this.reqDataMember).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              let data = res.data.hierarchy
              data.forEach(item => {
                item.isOpen = false
              })
              data = data.filter(item => {
                if (item.partnerLevel === 'NORMAL') {
                  if (item.cnt > 0) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  return true
                }
              })
              if (item) {
                this.selectMem = ''
                this.dailyList = []
                this.partnerInfo = ''
                item.children = data
              } else {
                this.memberList = data
              }
            }
          })
        } else {
          this.selectMem = ''
          this.dailyList = []
          this.partnerInfo = ''
          delete item.children
        }
      }
    },
    loadMemberList (item) {
      this.emitter.emit('Loading', true)
      this.selectMem = item
      this.reqDataMember.memId = item ? item.memId : ''

      if (!item || item.cnt > 0) {
        if (!item || !item.children || item.children.length === 0) {
          memberListTree(this.reqDataMember).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              let data = res.data.hierarchy
              data.forEach(item => {
                item.isOpen = false
                item.isOpenMemo = false
              })
              data = data.filter(item => {
                if (item.partnerLevel === 'NORMAL') {
                  if (item.cnt > 0) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  return true
                }
              })
              if (item) {
                // item.children = data
              } else {
                this.memberList = data
              }
            }
          })
        }
      }
      if (item) {
        this.loadList(item.memId)
      } else {
        this.emitter.emit('Loading', false)
      }
    },
    async setStatus (item) {
      const req = {
        memId: item.memId,
        memBlackYn: item.isMemBlack === true ? 'Y' : 'N',
        memLockYn: item.isMemLock === true ? 'Y' : 'N'
      }
      const res = await setMemberMemo(req)
      if (res.resultCode === '0') {
        item.memBlackYn = req.memBlackYn
        item.memLockYn = req.memLockYn

        item.memColor = 'blue'
        if (item.isMemBlack === true) {
          item.memColor = 'black'
        }
        if (item.isMemLock === true) {
          item.memColor = 'red'
        }
        alert('상태 수정 완료')
      } else {
        alert('상태 수정  실패, 다시 시도해주세요.')
      }

      this.isOpenStatus = false
    },
    async setCash (item, type) {
      if (!item.newCash) {
        alert('금액을 입력해주세요.')
      } else {
        if (type === 'in') {
          const req = {
            memId: item.memId,
            inAmt: item.newCash,
            memo: '',
            type: '',
            inBonusYn: 'N'
          }
          if (confirm('지급처리하시겠습니까?')) {
            const res = await cashIn(req)
            if (res.resultCode === '0') {
              alert('지급완료')
              item.cashAmt = item.cashAmt + item.newCash
              return
            } else {
              alert('지급실패, 다시 시도해주세요.')
            }
          }
        } else {
          const req = {
            memId: item.memId,
            outAmt: item.newCash,
            memo: '',
            type: '',
            bonusRollingYn: 'N'
          }
          if (confirm('회수처리하시겠습니까?')) {
            const res = await cashOut(req)
            if (res.resultCode === '0') {
              alert('회수 완료')
              item.cashAmt = item.cashAmt - item.newCash
              return
            } else {
              if (res.resultCode === 'C702') {
                alert('회수할 금액이 유저 보유금보다 많을 수 없습니다.')
              } else if (res.resultCode === 'C099') {
                alert(res.resultMessage)
              } else {
                alert('회수실패, 다시 시도해주세요.')
              }
            }
          }
        }
        item.newCash = ''
        this.isOpenCash = false
      }
    },
    async setPoint (item, type) {
      let res = ''
      if (!item.newPoint) {
        alert('금액을 입력해주세요.')
      } else {
        if (type === 'in') {
          const req = {
            memId: item.memId,
            pointType: 2,
            pointDesc: '관리자 포인트 지급',
            pointAmt: item.newPoint,
            memo: ''
          }
          if (confirm('지급처리 하시겠습니까?')) {
            res = await memberPointInOut(req)
            if (res.resultCode === '0') {
              alert('지급완료')
              item.pointAmt = item.pointAmt + item.newPoint
            } else if (res.resultCode === 'P006') {
              alert('포인트지급,회수 처리는 1분에 1건씩 처리됩니다. 잠시후 다시 시도해주세요')
            } else {
              alert('지급실패, 다시 시도해주세요.')
            }
          }
        } else {
          if (item.pointAmt < item.newPoint) {
            // model.amt = 0
            alert('회수포인트는 보유포인트보다 적어야 합니다.')
            return
          }
          const req = {
            memId: item.memId,
            pointType: -2,
            pointDesc: '관리자 포인트 회수',
            pointAmt: item.newPoint * -1,
            memo: ''
          }
          if (confirm('회수처리 하시겠습니까?')) {
            res = await memberPointInOut(req)
            if (res.resultCode === '0') {
              alert('회수완료')
              item.pointAmt = item.pointAmt - item.newPoint
            } else {
              alert('회수실패, 다시 시도해주세요.')
            }
          }
        }
      }
    },
    async setMemo (item) {
      if (!item.memo) {
        alert('내용을 입력해주세요.')
      } else {
        const req = {
          memId: item.memId,
          memo: item.memo
        }
        const res = await setMemberMemo(req)
        if (res.resultCode === '0') {
          alert('메모 입력 완료')
        } else {
          alert('메모입력 실패, 다시 시도해주세요.')
        }
        item.isOpenMemo = false
      }
    },
    async setPassword (item) {
      const req = {
        memId: item.memId
      }
      const res = await setMemberResetPassword(req)
      if (res.resultCode === '0') {
        alert('비밀번호 초기화 완료')
      } else {
        alert('비밀번호 초기화 실패, 다시 시도해주세요.')
      }
      this.isOpenPassword = false
    },
    openMemberPopup (item, type) {
      this[type] = !this[type]
      this.selectMember = item
    },
    loadList (memId) {
      this.emitter.emit('Loading', true)
      this.reqData.treeUserId = memId

      this.partnerInfo = {}
      this.dailyList = []

      if (this.selectedLi === memId) {
        this.selectedLi = memId
      } else {
        this.selectedLi = memId
      }

      console.log('list param : ', this.reqData)

      this.normalMemberList = []

      if (!this.searchOptionText.text) {
        this.reqData.memId = ''
        this.reqData.recommenderId = ''
        this.reqData.memNick = ''
        this.reqData.memName = ''
      } else {
        if (this.searchOptionText.option === 'memId') {
          this.reqData.memId = this.searchOptionText.text
          this.reqData.recommenderId = ''
          this.reqData.memNick = ''
          this.reqData.memName = ''
        } else if (this.searchOptionText.option === 'recommenderId') {
          this.reqData.memId = ''
          this.reqData.recommenderId = this.searchOptionText.text
          this.reqData.memNick = ''
          this.reqData.memName = ''
        } else if (this.searchOptionText.option === 'memNick') {
          this.reqData.memId = ''
          this.reqData.recommenderId = ''
          this.reqData.memNick = this.searchOptionText.text
          this.reqData.memName = ''
        } else if (this.searchOptionText.option === 'memName') {
          this.reqData.memId = ''
          this.reqData.recommenderId = ''
          this.reqData.memNick = ''
          this.reqData.memName = this.searchOptionText.text
        } else if (this.searchOptionText.option === 'memPhone') {
          this.reqData.memId = ''
          this.reqData.recommenderId = ''
          this.reqData.memNick = ''
          this.reqData.memName = ''
          this.reqData.memPhone = this.searchOptionText.text
        }
      }

      memberListByPartner(this.reqData).then(res => {
        if (res.resultCode === '0') {
          this.pageInfo = res.data.pageInfo
          this.totalInfo = res.data.totalInfo
          const normalMemberList = res.data.list
          normalMemberList.forEach(item => {
            if (item.lastLoginDt) {
              const _date = new Date(item.lastLoginDt)
              item.lastLoginDt = this.getDateStr(_date, 'yy-MM-dd HH:mm:ss')
            }

            item.isMemLock = item.memLockYn === 'Y'
            item.isMemBlack = item.memBlackYn === 'Y'

            item.memColor = 'blue'

            if (item.memLockYn === 'Y') {
              item.memColor = 'red'
            } else {
              if (item.memBlackYn === 'Y') {
                item.memColor = 'black'
              }
            }
            for (const key in item) {
              if (item[key] !== 0 && !item[key]) {
                item[key] = '-'
              }
            }
            item.isOpenMemo = false
            // const topUserList = item.topUserList
            // item.isTopUserList = topUserList.length > 1
            // item.isTopUserListOver = topUserList.length > 4
            // topUserList.shift()
            // item.topUserListCalculate = topUserList.reverse()
          })

          this.normalMemberList = normalMemberList
          console.log('normalMemberList: ', normalMemberList)

          this.emitter.emit('Loading', false)
        }
      })
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        const targetDate = new Date()
        targetDate.setDate(1)
        targetDate.setHours(0, 0, 0)
        _date = targetDate
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      console.log('setEndDate : ', date)
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    }
  },
  created () {
    this.loadMemberList()
    // this.setStartDate()
    // this.setEndDate()
  }
}
</script>
<style scoped>
.section {
  display: flex;
  padding-top: 25px;
  gap: 25px;
}
.box {
  min-height: 600px;
  padding: 20px;
  box-sizing: border-box;
}
.left{
  width: 355px;
  min-width: 320px;
  background-color: #fbfbfb;
  border: solid 1px #d6d6d6;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.right{
  width: calc(100% - 355px);
  min-width: 1200px;
  padding: 0;
  border: 0;
}
.left h2{
  padding: 5px 10px 10px;
  color: #3d3d3d;
  font-size: 14px;
  font-weight: 800;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left .toggle-item {flex-direction: row;align-items: center;}
.right h2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  color: #5c5e60;
  font-weight: 600;
}
.headinfo {
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.headinfo li {
  align-items: center;
  display: flex;
  gap: 5px;
}
.info{
  margin-right: 5px;
}
.info em{
  background: #052659;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
}
.info span {
  color: #aeb7c4;
}
.headinfo li:nth-child(2) em {
  color: #eb7b3f;
}
.headinfo li:nth-child(3) em {
  color: #82a6a4;
}
.headinfo li:nth-child(4) em {
  color: #806fad;
}
.headinfo li:nth-child(5) em {
  color: #f6ac01;
}
.rate span{
  padding: 3px 5px;
  background:#eee;
  border-radius: 3px;
  color:#5c5e60;
}
.tablist{
  display: flex;
  margin-top: 20px;
}
.tablist li{
  padding: 12px 17px;
  border-radius: 3px 3px 0 0;
  border: 1px solid #bbb;
  border-right:0;
  box-sizing: border-box;
  color: #707070;
  font-size:13px;
  cursor: pointer;
}
.tablist li.on{
  background: #f9f9f9;
  border-top: 5px solid #eb7a3f;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.16);
  color: #5c5e60;
  font-weight: 800;
  z-index: 2;
}
.tablist li:last-child{
  border-right: 1px solid #bbb;
}
.inout{
  color: #600e89;
}
.total td{
  background: #c0ccdb;
}
.rightline{
  border-right: 1px solid #b1b1b1;
}
.list{
  padding: 0 10px;
}
.list li{
  position: relative;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: -1px;
  color: #5c5e60;
}
.list li > span {
   position: absolute;
   left: -18px;
   top: 14px;
   font-size: 16px;
   width: 14px;
   height: 14px;
   border-radius: 2px;
   background-color: #696969;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
}
.list li > span.moreOn {
  /*transform: rotate(90deg);*/
}
.list li > span::before {content: '+';}
.list li > span.moreOn::before {content: '—';}
.list li > span.none::before {content: '•';}
.list li > div {
  padding: 10px 0;
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: 12px;
}
.list li div.active {
  background-color: rgba(21, 228, 28, 0.26);
  padding: 3px;
}
.list span{
  color: #44b4ab;
}
.list .nick {font-size: 13px;margin-left: 5px;}
.list em {
  width: 38px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.morebtn {
  position:relative;
}
/* .morebtn::before{
  content: "▶";
  position: absolute;
  left: -17px;
  top: 12px;
}
.morebtn.on::before {
  transform: rotate(90deg);
  color:#0a7b0e;
}
.moreOn::before{
  transform: rotate(90deg);
  color:#db8806;
} */
.more{
  padding-left: 23px;
  position: relative;
}
/*.more::before {
  content: "";
  position: absolute;
  height: calc(100% - 30px);
  width: 1px;
  left: 13px;
  top: 16px;
  background: #eee;
}*/
.more .more{
  padding-left: 23px;
}
.PTN_1 em{
  background: #2b476e;
}
.PTN_2 em{
  background: #363b72;
}
.PTN_3 em{
  background: #1d505c;
}
.PTN_4 em{
  background: #af2a2e;
}
.PTN_5 em{
  background: #d98f00;
}
.PTN_1 .nick {color: #2b476e;}
.PTN_2 .nick {color: #363b72;}
.PTN_3 .nick {color: #1d505c;}
.PTN_4 .nick {color: #af2a2e;}
.PTN_5 .nick {color: #d98f00;}
.levelbox > span {width: 38px;height: 20px;color: #fff;font-size: 10px;border-radius: 2px;border: solid 1px #000;box-sizing: border-box;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
.levelbox .NORMAL {background: #df663a;}
.levelbox .PTN_1 {background: #2b476e;}
.levelbox .PTN_2 {background: #363b72;}
.levelbox .PTN_3 {background: #1d505c;}
.levelbox .PTN_4 {background: #af2a2e;}
.levelbox .PTN_5 {background: #d98f00;}
.name button{font-weight: bold;}
.nameId {display: block;margin-top: 5px;}
.action {display: flex;justify-content: center;align-items: center;gap: 10px;}
.action a:after {display: none;width: 90px;height: 30px;line-height: 30px;position: absolute;top: 33px;left: 50%;margin-left: -45px;color: #fff;border-radius: 5px;font-size: 12px;text-align: center;background: #353535;}
.actionbtn {overflow: inherit;}
.action a {position: relative;}
/*.action a:hover:before {content: "";position: absolute;top: 25px;left: 50%;width: 0;height: 0;margin-left: -6px;border-right: 6px solid transparent;border-left: 6px solid transparent;border-bottom: 8px solid #353535;}
.action a:hover:after {display: block;}
.action a:after {display: none;width: 90px;height: 30px;line-height: 30px;position: absolute;top: 33px;left: 50%;margin-left: -45px;color: #fff;border-radius: 5px;font-size: 12px;text-align: center;background: #353535;}
.memberb:after {content: "회원상태변경";}
.moneyb:after {content: "캐시 지급/회수";}
.pointb:after {content: "포인트 지급/회수";}
.memob:after {content: "메모";}
.pwb:after {content: "비밀번호 변경";}*/

.phone, .acc {
  position: relative;
}
.phone .popup::after, .acc .popup::after {
  border-color: #f5f5f5 transparent;
  border-style: solid;
  border-width: 8px 6px 0px 6.5px;
  content: '';
  display: block;
  position: absolute;
  bottom: -7px;
  width: 0;
  z-index: 1;
}

.phone .popup::before, .acc .popup::before {
  border-color: #bcc2c7 transparent;
  border-style: solid;
  border-width: 8px 6px 0 6.5px;
  content: '';
  display: block;
  position: absolute;
  bottom: -9px;
  width: 0;
  z-index: 0;
}
.phone .popup::after,
.phone .popup::before {right: 5px;}

.acc .popup::after,
.acc .popup::before {left: 5px;}
.popup {
  /*display: none;*/
  position: absolute;
  border-radius: 7px;
  border: solid 1px #bcc2c7;
  background-color: #f5f5f5;
  color: #585963;
  font-size: 11px;
  padding: 4px 9px;
  z-index: 1;
  bottom: 30px;
  /*left: 50%;
  transform: translateX(-50%);*/
}
.phone .popup {right: 0;}
.acc .popup {left: 0;}
.phone:hover .popup,
.acc:hover .popup {
  display: block;
}

.money li:first-child {margin-top: 0;}
.money li {display: flex;justify-content: space-between;align-items: center;margin-top: 7px;}
.money em {width: 80px;text-align: left;padding-left: 15px;box-sizing: border-box;}
.money span {width: 100px;text-align: right;display: inline-block;padding-right: 15px;box-sizing: border-box;}
.ipwrap{display: flex;flex-direction: column;align-items: center;gap: 5px;}
.ip {display: block; width: 150px; white-space: normal; word-wrap: break-word; text-align: center;}
.orc {color: #ff7e00;}
.rdc {color: #e50000;}

.mainTable th img {vertical-align: middle;}

.memo-btn {position: relative;background: transparent;}
.memo-btn > a {color: #5c5c5c;justify-content: center;}

/* modal */
.modal-wrapper {display: flex;}
.modal-wrap {padding: 0;width: 550px;min-width: 550px;margin-top: 350px;}
.page-contents-wrap {padding: 0;background: #353535;}
.sub-title {color: #fff;font-size: 14px;height: 42px;display: flex;align-items: center;justify-content: center;position: relative;border-bottom: 2px solid transparent;}
.sub-title .btn {position: absolute;right: 15px;background: no-repeat;font-size: 22px;width: auto;height: auto;cursor: pointer;}
.page-content {padding: 27px 0 25px;color: #fff;}
.page-content .content {text-align: center;}
.page-content .content p {font-size: 14px;text-align: center;}
.page-content .content p span {margin-left: 20px;display: inline-block;border-radius: 18px;padding: 1px 12px;}
.page-content .content p span.normal {background: #0f84ee;}
.page-content .content p span.black {background: #808080;}
.page-content .content p span.stop {background: #e1534e;}
.page-content .content .setting {display: flex;justify-content: center;gap: 50px;margin-top: 20px;text-align: center;}
.page-content .content .setting em {font-size: 16px;display: block;margin-bottom: 10px;}
.page-content .content .btnWrap {display: flex;align-items: center;justify-content: center;gap: 20px;margin-top: 20px;}
.page-content .content .btnWrap a {width: 168px;height: 35px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: #fff;cursor: pointer;}
.page-content .content .btnWrap a.btn {border: 1px solid #a4a4a4;box-sizing: border-box;background: #545454;}
.page-content .content .input {border: 1px solid #353535;font-size: 14px;width: 356px;height: 30px;margin-top: 20px;}
.page-content .content .input::placeholder, .page-content .content textarea::placeholder {color: #a4a4a4;}
.page-content .content textarea {resize: none;width: 480px;height: 112px;padding: 10px 12px;font-size: 14px;}

.modal-wrap.name .sub-title {border-color: #e1534e;}
.modal-wrap.name .sub-title .btn {color: #e1534e;}
.modal-wrap.name .btnWrap a {background: #e1534e;}

.modal-wrap.cash .sub-title {border-color: #369e17;}
.modal-wrap.cash .sub-title .btn {color: #369e17;}
.modal-wrap.cash .btnWrap a {background: #369e17;}

.modal-wrap.point .sub-title {border-color: #d322fd;}
.modal-wrap.point .sub-title .btn {color: #d322fd;}
.modal-wrap.point .btnWrap a {background: #d322fd;}

.modal-wrap.memo .sub-title {border-color: #0f84ee;}
.modal-wrap.memo .sub-title .btn {color: #0f84ee;}
.modal-wrap.memo .btnWrap a {background: #0f84ee;}

.modal-wrap.password .sub-title {border-color: #f6ac00;}
.modal-wrap.password .sub-title .btn {color: #f6ac00;}
.modal-wrap.password .btnWrap a {background: #f6ac00;}
.ft16 {font-size: 16px !important;}
.onlyNum {position: relative;}
.page-content .content .input#newCash {margin-top: 0;}
#newCash {
   width: 110px;
   height: 20px;
   color: transparent;
   background-color: transparent;
   border: 1px solid #000;
   border-radius: 0;
   position: relative;
   z-index: 1;
}
#newCash::selection{
  background:none;
}
#newCash+label {
   font-size: 12px;
   background: #fff;
   color: #000;
   height: 20px;
   padding: 0 6px;
   box-sizing: border-box;
   width: 110px;
   position: absolute;
   left:0;
   top:0;
   display:flex;
   align-items: center;
   justify-content: flex-start;
}
.page-content .content .input#newPoint {margin-top: 0;}
#newPoint {
   width: 110px;
   height: 20px;
   color: transparent;
   background-color: transparent;
   border: 1px solid #000;
   border-radius: 0;
   position: relative;
   z-index: 1;
}
#newPoint::selection{
  background:none;
}
#newPoint+label {
   font-size: 12px;
   background: #fff;
   color: #000;
   height: 20px;
   padding: 0 6px;
   box-sizing: border-box;
   width: 110px;
   position: absolute;
   left:0;
   top:0;
   display:flex;
   align-items: center;
   justify-content: flex-start;
}
</style>
